import React from "react";
import './ppn.css';

const ProviderCta = () => {
  return (
    <div className="provcta-container">
      <h1>Unite with us to empower lives, one mind at a time</h1>
      <p>Start with this brief questionairre</p>
      <button className="primary-btn"><a className="a2" href="https://previdence.com/#/ppnapplication" target="_blank" rel="noreferrer" >Join Today</a></button>
    </div>
  );
};
export default ProviderCta;